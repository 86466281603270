import { graphql } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"
import { createLocaleTextGetter } from "../../localize"
import tw, { styled } from "twin.macro"
import { LocaleContext } from "../components/LocaleContext"
import { PortableText } from "@portabletext/react"

export const query = graphql`
  fragment policyPageBlocksFragment on SanityPolicyPage {
    title {
      ...translatable
    }
    notes {
      ...translatable
    }
    body {
      ...translatableRichText
    }
    version
    policyType
  }

  query ($regex: String!) {
    sanityPolicyPage(_id: { regex: $regex }) {
      _id
      ...policyPageBlocksFragment
    }
  }
`

const PolicyPageWrapper = styled.div`
  ${tw`w-full flex items-center justify-center`}
  background: white;
`

const Container = styled.div`
  ${tw`w-full prose px-4 py-16`}
`

const PolicyPage = ({ pageContext, data, location }) => {
  const getLocalizedContent = createLocaleTextGetter(pageContext.locale)
  const { sanityPolicyPage: page } = getLocalizedContent(data)

  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout location={location}>
        <PolicyPageWrapper>
          <Container>
            <small>
              Version {page.version}
            </small>
            <PortableText value={page.body} />
          </Container>
        </PolicyPageWrapper>
      </Layout>
    </LocaleContext.Provider>
  )
}

export default PolicyPage
